
.foo-menu {
    position: relative;
    z-index: 1000 !important;
}

.menu-active .top-nav-bar {
    z-index: 80;
}

.top-nav-bar {
    position: relative;
    background-color: #008AE6;
    color: #FFF;
}

.am-navbar-title {
    color: #FFF !important;
}

.menu-mask {
    content: ' ';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
    z-index: 79;
}

#main_header {
    z-index: 2000;
}

#main_header {
    position: fixed;
}

#main_header {
    width: 100%;
}

#main_header {
    background-color: white;
}


header .head_search .head_search_cont input:focus {
    border-radius: .5pc 6pt 0pc 0pc;
    z-index:1500
}

header [class~=head_user] [class~=btn][class~=login] {
    color: #d2d2d2;
}

header {
    height: 4.0625pc;
    background-color: #ffffff;
    position: absolute;
    top: 0px;
}




header [class~=head_search] [class~=head_search_cont] input {
    height: .354166667in;
}


header [class~=head_search] [class~=head_search_cont] input {
    background: #f7f7f7;
}


header .head_search .head_search_cont {
    position: relative;
}

header [class~=head_user] [class~=btn][class~=login] {
    font-weight: 500;
}

.sidebar-out.collapse-sidebar .main_sidebar {
    z-index: 1000;
}

.sidebar-out.collapse-sidebar .main_sidebar {
    position: fixed;
}

.sidebar .sidebar_innr .sections li a {
    display: flex;
}

.sidebar .sidebar_innr .sections li a, .sidebar .sidebar_innr .sections ul {
    padding-top: 0;
}

header [class~=header-traiger] {
    left: 20px;
    top: 16pt;
}

[class~=sidebar] [class~=sidebar_innr] [class~=sections] li[class~=active] a:before, header [class~=header-traiger] {
    position: absolute;
}

header [class~=header-traiger] {
    font-size: 23px;
}

header [class~=header-traiger] {
    cursor: pointer;
}

header #logo, header [class~=header-traiger] {
    position: relative;
}


header #logo {
    left: 48.75pt;
    top: -10pt;
    font-size: 23px;
    color: #000000;
}

#logo [class~=logo-inverse] {
    display: inline;
}

header [class~=header-traiger] {
    padding-left: 6.75pt;
}

[class~=uk-light] header [class~=header-traiger] {
    color: white;
}

header [class~=header-traiger] {
    padding-bottom: 6.75pt;
}

header [class~=header-traiger] {
    padding-top: 6.75pt;
}

header [class~=header-traiger] {
    left: 20px;
}

header {
    box-shadow: 0pt .052083333in 11.25pt -5.25pt rgba(0, 0, 0, .1);
}

header {
    position: sticky;
}

header {
    padding-top: 0pt;
}

header {
    padding-right: .364583333in;
}

header {
    padding-bottom: 0pt;
}


header, [class~=main_sidebar] [class~=sidebar-header] {
    align-items: center;
}

header, [class~=main_sidebar] [class~=sidebar-header] {
    display: flex;
}

header {
    height: 4.0625pc;
}

header [class~=head_search] [class~=head_search_cont] input {
    padding-top: 0;
}

header [class~=head_search] [class~=head_search_cont] > i[class~=s_icon], header #logo {
    position: absolute;
}

header [class~=uk-light] [class~=head_search] [class~=head_search_cont] input {
    background-color: white;
}

header [class~=head_search] [class~=head_search_cont] input {
    height: .354166667in;
}

header [class~=head_search] [class~=head_search_cont] input {
    background: #f7f7f7;
}

header .head_search .head_search_cont {
    position: relative;
}

header [class~=head_search] [class~=head_search_cont] input {
    color: #393939;
}

header [class~=head_search] [class~=head_search_cont] > i[class~=s_icon] {
    top: 50%;
}

header [class~=head_search] [class~=head_search_cont] input {
    border-left-color: rgba(0, 0, 0, 0);
}


header [class~=head_search] [class~=head_search_cont] > i[class~=s_icon] {
    left: 12.75pt;
}

header [class~=head_search] [class~=head_search_cont] input {
    border-bottom-color: rgba(0, 0, 0, 0);
}

header [class~=head_search] [class~=head_search_cont] input {
    border-right-color: rgba(0, 0, 0, 0);
}

header [class~=head_search] [class~=head_search_cont] input {
    border-top-color: rgba(0, 0, 0, 0);
}

header [class~=head_search] [class~=head_search_cont] input {
    border-radius: .083333333in;
}

header [class~=head_search] [class~=head_search_cont] input {
    border-radius: 6.25pc;
}

header [class~=head_search] [class~=head_search_cont] input {
    padding-left: 45px;
}

header [class~=head_search] [class~=head_search_cont] input, #foot ul {
    padding-bottom: 0;
}

header [class~=head_search] [class~=head_search_cont] input {
    padding-right: 1.875pc;
}

header [class~=head_search] [class~=head_search_cont] > i[class~=s_icon] {
    color: #737373;
}

header [class~=head_search] [class~=head_search_cont] input {
    padding-top: 0;
}

header [class~=head_search] [class~=head_search_cont] input {
    width: 23.75pc;
}

header [class~=head_search] [class~=head_search_cont] input {
    outline: 0;
}

header .head_search .head_search_cont input:focus {
    border-radius: .5pc 6pt 0pc 0pc;
}

header [class~=head_search] [class~=head_search_cont] > i[class~=s_icon], header #logo {
    position: absolute;
}

header [class~=uk-light] [class~=head_search] [class~=head_search_cont] input {
    background-color: white;
}

header [class~=head_search] [class~=head_search_cont] input {
    height: .354166667in;
}

header [class~=head_search] [class~=head_search_cont] input {
    background: #f7f7f7;
}

a:active, a:hover {
    outline: none;
}

a, .uk-link {
    color: #929292;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease 0s;
}
.uk-link:hover{
    text-decoration: underline !important;
}
a:hover, .uk-link:hover, .uk-link-toggle:hover .uk-link, .uk-link-toggle:focus .uk-link {
    color: #3e416d;
    text-decoration: none;
}